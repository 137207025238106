<template>
  <div
    class="w-full h-full "
    v-if="this.dataIsLoaded"
  >
    <!-- HEADER -->
    <div
      class="grid md:grid-cols-3 grid-cols-1  h-96  bg-green-main"
    >
      <div
        class=" flex-col justify-center items-center hidden md:flex"
        style="width: 200px; height:130px"
      >
        <img
          src="@/assets/capacio/login/logo.png"
          class="h-16 w-64"
        >
      </div>

      <div class="flex flex-col items-center pt-0 justify-center md:pt-20">
        <div
          class="height_hd"
        >
          <h1
            class="text-xl text-center text-white lg:text-left lg:text-4xl"
            id="title"
          >
            <span v-if="this.selectedLang === 'EN'">Ability Rating</span>
            <span v-else>Gruppskattningsformulär</span>
          </h1>
          <div class="p-2 height_hd">
            <p
              v-if="this.selectedLang === 'EN'"
              class="mt-5 text-sm text-white text-left leading-7"
            >
              Estimate the abilities of the individuals in the list. Start with yourself. When you
              have estimated all the individuals in the list, press "Submit". The
              questionnaire is anonymous. Be as sincere as you can.
              Hover the mouse over the ’1’ and the ’9’ of the scale to better understand the ability you are estimating.

            <!-- The estimate
            contains two sections. The first refers to objectively measured
            cognitive abilities. In the second section, you should estimate a
            number of characteristics. -->
            </p>
            <p
              v-else
              class="mt-5 text-sm text-center text-white lg:text-left"
            >
              Skatta individerna i listan. Börja med dig själv. När du har skattat
              alla individerna i listan tryck på ”skicka in”. Skattningen är
              anonym. Var så uppriktig du kan.

            <!-- Skattningen innehåller två
            sektioner. Den första avser objektivt uppmätta kognitiva förmågor. I
            den andra sektionen ska du skatta ett antal egenskaper. -->
            </p>
          </div>
          <div class="flex flex-row mx-auto justify-center items-center w-full mt-5">
            <select
              class="select ml-4 mr-4 text-sm"
              v-model="selectedUser"
            >
              <option
                selected
                disabled
              >
                <span v-if="this.selectedLang === 'EN'">Choose person</span>
                <span v-else>Välj person</span>
              </option>

              <!-- select users -->
              <option
                v-for="(user, index) in usersInfo"
                :key="index"
                :class="
                  hasAnsweredAllQuestionsForUser(index) ? 'text-gray-300' : ''
                "
                :value="user.name"
              >
                <div
                  v-if="hasAnsweredAllQuestionsForUser(index)"
                  class="text-black"
                >
                  ✓
                </div>
                <span>{{ user.name }}</span>
              </option>
            </select>
            <h4
              class="text-center text-white"
              v-if="selectedUser"
            >
              <span
                v-if="this.selectedLang === 'EN'"
              >You are rating
                {{
                  ratingUser.user.name === selectedUser
                    ? "yourself"
                    : selectedUser
                }}</span>
              <span
                v-else
              >Du skattar
                {{
                  ratingUser.user.name === selectedUser
                    ? "dig själv"
                    : selectedUser
                }}
              </span>
            </h4>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-primary-light">
      <div
        class="flex flex-col items-center px-2 py-8 space-y-20 bg-primary-light"
        v-if="dataIsLoaded && this.options.cognitive"
      >
        <div v-if="this.options.cognitive && this.options.traits">
          <h3
            v-if="this.selectedLang === 'EN' "
            style="border-bottom: black solid 2px; padding:2px;"
          >
            Cognitive Measures
          </h3>
          <h3
            v-else
            style="border-bottom: black solid 2px; padding:2px;"
          >
            Kognitiva Förmågor
          </h3>
        </div>

        <!-- CONGITIVE MEASURES -->
        <question
          v-for="(question, index) in questions.slice(0, 8)"
          :key="index"
          :ref="`q_${index}`"
          v-model="
            usersInfo[selectedUserIndex].formattedTeamRatingResults[index]
          "
          :index="index + 1"
          :gray="index % 2 === 0 ? true : false"
          :question-text="question"
          :scales="scales"
          :rate-between="true"
          :high-low-desc="[highLowDescs[question][0], highLowDescs[question][2]]"
          :highlight="
            usersInfo[selectedUserIndex].formattedTeamRatingResults[index] ===
              null && checkAnswers
          "
          :question-desc="questionDescs[question]"
          @input="scrollToQ(index + 1)"
        />
      </div>

      <!-- TRAITS -->
      <div
        class="flex flex-col items-center px-2 py-8 space-y-20 bg-primary-light"
        v-if="dataIsLoaded && questions.length > 8 && this.options.cognitive"
      >
        <h3
          v-if="this.selectedLang === 'EN'"
          style="border-bottom: black solid 2px; padding:2px;"
        >
          Traits
        </h3>
        <h3
          v-else
          style="border-bottom: black solid 2px; padding:2px;"
        >
          Egenskaper
        </h3>
        <question
          v-for="(question, index) in questions.slice(8, 23)"
          :key="index + 8"
          :ref="`q_${index + 8}`"
          v-model="
            usersInfo[selectedUserIndex].formattedTeamRatingResults[index + 8]
          "
          :index="index + 9"
          :gray="(index + 8) % 2 === 0 ? true : false"
          :question-text="question"
          :scales="scales"
          :rate-between="true"
          :highlight="
            usersInfo[selectedUserIndex].formattedTeamRatingResults[
              index + 8
            ] === null && checkAnswers
          "
          @input="scrollToQ(index + 8)"
        />
      </div>

      <!-- ONLY TRAITS OPTION, exists if only traits was selected -->
      <div
        class="flex flex-col items-center px-2 py-8 space-y-20 bg-primary-light"
        v-if="dataIsLoaded && questions.length > 8 && !this.options.cognitive && this.options.traits"
      >
        <h3
          v-if="this.selectedLang === 'EN'"
          style="border-bottom: black solid 2px; padding:2px;"
        >
          Traits
        </h3>
        <h3
          v-else
          style="border-bottom: black solid 2px; padding:2px;"
        >
          Egenskaper
        </h3>
        <question
          v-for="(question, index) in questions"
          :key="index"
          :ref="`q_${index}`"
          v-model="
            usersInfo[selectedUserIndex].formattedTeamRatingResults[index]
          "
          :index="index + 1"
          :gray="index % 2 === 0 ? true : false"
          :question-text="question"
          :scales="scales"
          :rate-between="true"
          :highlight="
            usersInfo[selectedUserIndex].formattedTeamRatingResults[index] ===
              null && checkAnswers
          "
          @input="scrollToQ(index + 1)"
        />
      </div>

      <!-- Submit buttons -->
      <div class="flex justify-center p-7">
        <button
          v-if="this.usersInfo.length - 1 > this.selectedUserIndex"
          class="w-56 btn bg-accent-green text-sm"
          @click="continueToNextPerson()"
        >
          <span v-if="this.selectedLang === 'EN'">Continue</span>
          <span v-else>Fortsätt</span>
        </button>
        <button
          v-else
          class="w-56 bg-yellow-500 btn text-sm"
          @click="submit()"
        >
          <span v-if="this.selectedLang === 'EN'">Submit</span>
          <span v-else>Lämna in</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Question from '../../Questionnaire/components/Question.vue'
import { mapGetters } from 'vuex'
import { CognitiveProfileStrengths, RequirementSpecification } from '@/content/reportContent.json'
import '@/modules/Questionnaire/questionnaire.scss'

export default {
  components: { Question },
  data () {
    return {
      highLowDescs: CognitiveProfileStrengths.abilityLevels,
      questionDescs: RequirementSpecification,
      title: '',
      description: '',
      scales: ['', '', '', '', '', '', '', '', ''],
      checkAnswers: false,
      selectedUser: '',
      usersInfo: [],
      dataIsLoaded: false
    }
  },
  props: {
    campaignId: {
      type: String,
      required: false,
      default: null
    }
  },
  computed: {
    ...mapGetters({
      teamRatingUsers: 'TEAM_RATING/getTeamRatingUsers',
      ratingUser: 'TEAM_RATING/getRatingUser',
      selectedLang: 'TEAM_RATING/getSelectedLang',
      questions: 'TEAM_RATING/getQuestions',
      questionsForData: 'TEAM_RATING/getQuestionsForData',
      options: 'TEAM_RATING/getOptions'
    }),
    selectedUserIndex () {
      return this.usersInfo.findIndex(user => user.name === this.selectedUser)
    }
  },
  watch: {
    selectedUser (_, oldUser) {
      const oldUserIndex = this.usersInfo.findIndex(user => user.name === oldUser)
      if (this.hasAnsweredAllQuestionsForUser(oldUserIndex)) {
        this.checkAnswers = false
        this.postResults(oldUserIndex)
      }
    }
  },

  methods: {
    // scroll to next question
    scrollToQ (index) {
      if (index >= this.questions.length) return
      const [{ $el }] = this.$refs[`q_${index}`]
      $el.scrollIntoView({ block: 'center', behavior: 'smooth' })
    },
    scrollToTop () {
      const el = document.getElementById('title')
      el.scrollIntoView({ block: 'center', behavior: 'smooth' })
    },
    // submit all answers if user has answered all questions
    submit () {
      this.checkAnswers = true // used for highlighting unanswered questions

      if (this.checkAllAnswers()) {
        this.checkAnswers = false
        this.postResults(this.selectedUserIndex)
        this.$router.push(`/TeamRating/${this.campaignId}/finished`)
        return
      }
      if (this.selectedLang === 'EN') {
        alert('You need to answer all the question for all people')
      } else {
        alert('Du måste svara på alla frågor på alla personer.')
      }
    },
    continueToNextPerson () {
      this.checkAnswers = true // used for highlighting unanswered questions

      if (this.hasAnsweredAllQuestionsForUser(this.selectedUserIndex)) {
        this.checkAnswers = false
        // go to next user to rate
        this.selectedUser = this.usersInfo[this.selectedUserIndex + 1].name
        this.scrollToTop()
      }
    },
    // check if person has answered all questions for person with user index
    hasAnsweredAllQuestionsForUser (userIndex) {
      const hasNotAnsweredAll = this.usersInfo[
        userIndex
      ].formattedTeamRatingResults.some(i => i == null)
      return !hasNotAnsweredAll
    },
    // check all answers for all people
    checkAllAnswers () {
      const checks = []
      this.usersInfo.forEach(user => {
        checks.push(
          user.formattedTeamRatingResults.every(answer => answer !== null)
        )
      })

      return checks.every(a => a === true)
    },
    postResults (userIndex) {
      const results = []
      this.usersInfo[userIndex].formattedTeamRatingResults.forEach(
        (result, index) => {
          results.push({
            teamRatingUserId: this.ratingUser.id,
            ratedTeamRatingUserId: this.usersInfo[userIndex]
              .teamRatingUserId,
            campaignId: this.campaignId,
            measure: this.questionsForData[index],
            rating: parseInt(result)
          })
        }
      )
      this.$api.updateTeamRatingResults(results)
    },
    // post request to change or add new results for all the users in the battery
    postAllResults () {
      const allResults = []
      this.usersInfo.forEach(user => {
        user.formattedTeamRatingResults.forEach((answer, resultIndex) => {
          allResults.push({
            teamRatingUserId: this.ratingUser.id,
            ratedTeamRatingUserId: user.teamRatingUserId,
            campaignId: this.campaignId,
            measure: this.questionsForData[resultIndex],
            rating: parseInt(answer)
          })
        })
      })
      this.$api.updateTeamRatingResults(allResults)
    },
    // get the previous added results and format them into the questionnaire
    async getUsersData () {
      this.selectedUser = this.ratingUser.user.name

      const usersInformation = []
      this.teamRatingUsers.forEach(teamRatingUser => {
        usersInformation.push({
          name: teamRatingUser.user.name,
          email: teamRatingUser.user.email,
          teamRatingUserId: teamRatingUser.id,
          userId: teamRatingUser.userId
        })
      })

      const teamRatingResults = await this.$api.getTeamRatingResultsForUser(
        this.campaignId,
        this.ratingUser.id
      )
      usersInformation.forEach(user => {
        user.teamRatingResults = {}
        teamRatingResults.forEach(result => {
          if (result.ratedTeamRatingUserId === user.teamRatingUserId) {
            user.teamRatingResults[result.measure] = result.rating
          }
        })
        user.formattedTeamRatingResults = []

        this.questionsForData.forEach(question => {
          if (user.teamRatingResults[question] === undefined) {
            user.formattedTeamRatingResults.push(null)
          } else {
            user.formattedTeamRatingResults.push(
              user.teamRatingResults[question]
            )
          }
        })
      })

      this.usersInfo = usersInformation

      // make the rating user in first place of the select tag
      this.usersInfo.unshift(
        this.usersInfo.splice(
          this.usersInfo.findIndex(
            item => item.name === this.ratingUser.user.name
          ),
          1
        )[0]
      )
      this.dataIsLoaded = true
    }
  },
  mounted () {
    this.getUsersData()
  }
}
</script>

<style scoped>
.height_hd {
  max-width: 900px;
}

@media (min-width: 1024px) {
  .height_hd {
    width: 900px;
  }
}
</style>
