<template>
  <div class="flex flex-col items-center">
    <input
      @input="e => $emit('input', e.target.value)"
      :value="value"
      type="range"
      :min="1"
      :max="9"
      class="w-11/12 h-4 mb-1 bg-gray-200 rounded-lg appearance-none cursor-pointer"
      :step="2"
    >
    <div
      v-if="type === 'importance'"
      class="flex justify-between w-full text-xs text-center mt-8"
    >
      <span class="w-32 leading-normal">Not important at all</span>
      <span class="w-32 leading-normal">Of little importance</span>
      <span class="w-32 leading-normal">Of average importance</span>
      <span class="w-32 leading-normal">Very important</span>
      <span class="w-32 leading-normal">Absolutely essential</span>
    </div>
    <!-- <div
      v-else-if="type === 'reqspec' "
      class="flex justify-between w-full text-xs text-center mt-8"
    >
      <span class="w-24 leading-normal">Disagree Strongly</span>

      <span class="w-24 leading-normal">Disagree A Little</span>

      <span class="w-24 leading-normal">Neither agree nor disagree</span>

      <span class="w-24 leading-normal">Agree A little</span>

      <span class="w-24 leading-normal">Agree Strongly</span>
    </div> -->
    <div
      v-else

      class="flex justify-between w-full text-xs text-center mt-8"
    >
      <span class="w-24 leading-normal">Very rarely</span>

      <span class="w-24 leading-normal">Rarely</span>

      <span class="md:w-24 w-28 leading-normal">Occasionally</span>

      <span class="w-24 leading-normal">Frequently</span>

      <span class="w-24 leading-normal">Very frequently</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  }
}
</script>

<style>

</style>
