<template>
  <div class="form-control flex-1">
    <label
      class="cursor-pointer label flex flex-col"
    >

      <slot />

    </label>
  </div>
</template>

<script>
export default {
}
</script>

<style>

</style>
