<template>
  <div class="form-control flex-grow">
    <label
      class="cursor-pointer label flex flex-col"
    >

      <span class="label-text text-center text-sm text-primary-dark my-2">{{ scale }}</span>
      <slot />

    </label>
  </div>
</template>

<script>
export default {
  props: {
    scale: {
      type: String,
      required: true
    }
  }
}
</script>

<style>

</style>
