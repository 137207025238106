<template>
  <div>
    <div v-if="recspec === true">
      <div
        class="flex flex-col mt-8 mb-4 box-shadow-radius w-full indicator height_qn"
        :class="{'opacity-60': value != null && highLowDesc === null, 'color1': gray, 'color2': !gray, 'border-red-700': highlight, 'border': highlight,}"
      >
        <div
          class="indicator-item hidden lg:block indicator-start badge badge-lg-qus badge-primary"
          :class="{'opacity-60': value != null}"
        >
          <span class="p-4 text-sm">  {{ index }}. </span>
        </div>
        <div class="w-full table-heder-background-color drop-shadow-md rounded-tr-md rounded-tl-md">
          <h6
            v-if="questionDropdown === null"
            class="font-semibold text-xl leading-7 pt-8 color-gray pr-8 pl-8 pb-4 my-3"
            :class="{'opacity-60': value != null}"
          >
            <span class="block lg:hidden">{{ `${index}. ${questionText}` }}</span>
            <span class="hidden lg:block text-base">{{ questionText }}</span>
          </h6>
          <div
            v-else
            class="dropdown dropdown-hover flex justify-center py-8"
          >
            <h6
              tabindex="0"
              class="text-center lg:text-xl text-primary-dark cursor-help"
              :class="{'opacity-60': value != null}"
            >
              <h6 class="font-semibold text-xl leading-7 pt-8 color-gray pr-8 pl-8">
                Simple Attention
              </h6>
            </h6>
            <div
              tabindex="0"
              class="dropdown-content p-2 shadow bg-base-100 rounded-box cursor-help"
              style="width: 350px"
            >
              <p>
                {{ questionDropdown }}
              </p>
            </div>
          </div>

          <!-- <p class="text-base font-normal pb-8 text-slate-500 mt-1 pr-8 pl-8 mt-4 leading-normal">
          Lorem Ipsum
        </p> -->
        </div>
        <div class="flex flex-row justify-between">
          <div class="w-full mt-6 mb-6 pr-12 pl-12">
            <span class="block lg:hidden">{{ `${index}. ${questionText}` }}</span>
            <p class="text-base font-normal text-slate-500">
              {{ questionText }}
            </p>
            <br
              class="br-req-spec"
            >
            <slider-input
              class="self-center mt-12 mb-6"
              type="importance"
              v-model="lsh"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-if="recspec === false">
      <div
        class="shadow-md p-2 rounded-xl indicator height_qn py-6 my-6"

        :class="{'opacity-60': value != null && highLowDesc === null, 'color1': gray, 'color2': !gray, 'border-red-700': highlight, 'border': highlight,}"
      >
        <div
          class="indicator-item hidden lg:block indicator-start badge badge-lg-qus badge-primary"
          :class="{'opacity-60': value != null}"
        >
          <span class="p-4 text-sm">  {{ index }}. </span>
        </div>
        <div
          class="w-full"
        >
          <div v-if="questionDesc != null">
            <h6 class="font-semibold text-xl leading-7 pt-8 color-gray pr-8 pl-8">
              <span class="block lg:hidden">{{ `${index}. ${questionText}` }}</span>
              <span class="hidden lg:block text-base">{{ questionText }}</span>
            </h6>
            <p class="text-base font-normal pb-8 text-slate-500 mt-1 pr-8 pl-8 mt-4 leading-normal">
              {{ questionDesc }}
            </p>
          </div>
          <div v-else>
            <h6
              v-if="questionDropdown === null"
              class="text-center lg:text-xl text-primary-dark "
              :class="{'opacity-60': value != null}"
            >
              <span class="block lg:hidden">{{ `${index}. ${questionText}` }}</span>
              <span class="hidden lg:block text-base">{{ questionText }}</span>
            </h6>
            <div
              v-else
              class="dropdown dropdown-hover flex justify-center"
            >
              <h6
                tabindex="0"
                class="text-center lg:text-xl text-primary-dark cursor-help"
                :class="{'opacity-60': value != null}"
              >
                <span class="block lg:hidden">{{ `${index}. ${questionText}` }}</span>
                <span class="hidden lg:block text-base">{{ questionText }}</span>
              </h6>
              <div
                tabindex="0"
                class="dropdown-content p-2 shadow bg-base-100 rounded-box cursor-help"
                style="width: 350px"
              >
                <p>
                  {{ questionDropdown }}
                </p>
              </div>
            </div>
          </div>

          <div
            v-if="!rateBetween"
            class="mt-8 flex flex-row items-end"
          >
            <radio-input
              v-for="(scale,indexS) in scales"
              :key="indexS"
              :scale="scale"
              :scale-between="false"
              :last="indexS === 0"
            >
              <input
                :value="indexS+1"
                :checked="indexS+1 === value"
                type="radio"
                class="radio radio-accent lg:radio-lg"
                @input="$emit('input', indexS+1)"
              >
            </radio-input>
          </div>
          <div
            v-else
            class="flex items-center px-4"
          >
            <div class="hidden lg:block text-center w-60 text-sm leading-7 capitalize">
              {{ scales[0].toLowerCase() }}
            </div>
            <div

              class="mt-4 flex flex-row items-center height_rb mx-auto"
            >
              <rate-between-input
                v-for="(scale, indexS) in scales"
                :key="indexS"
              >
                <input
                  :value="indexS+1"
                  :checked="indexS+1 === value"
                  type="radio"
                  class="radio radio-accent radio-md"
                  @input="$emit('input', indexS+1)"
                >
                <div v-if="highLowDesc === null || ( indexS != 0 && indexS != 8)">
                  <div
                    :class="{'opacity-60': value != null}"

                    v-if="scales.length === 10 || scales.length === 9"
                  >
                    {{ indexS + 1 }}
                  </div>
                </div>

                <div
                  v-else
                  class="dropdown dropdown-hover"
                >
                  <div
                    v-if="scales.length === 10 || scales.length === 9"
                    :class="{'opacity-60': value != null}"
                  >
                    {{ indexS + 1 }}
                  </div>
                  <div
                    tabindex="0"
                    class="shadow dropdown-content bg-white font-normal normal-case leading-normal text-base rounded-box p-4"
                    style="white-space: normal; width: 400px"
                  >
                    {{ (indexS === 0) ? highLowDesc[0] : highLowDesc[1] }}
                  </div>
                </div>
              </rate-between-input>
            </div>
            <div class="text-center hidden lg:block w-60 text-sm leading-7 capitalize">
              {{ scales[scales.length-1].toLowerCase() }}
            </div>
          </div>
          <div
            v-if="rateBetween"
            class="flex lg:hidden justify-between"
          >
            <div class="w-20 text-center text-2xs">
              {{ scales[0] }}
            </div>
            <div class="text-center w-20 text-2xs">
              {{ scales[scales.length-1] }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RadioInput from './RadioInput.vue'
import RateBetweenInput from './RateBetweenInput.vue'
import SliderInput from './SliderInput.vue'
export default {
  components: {
    RadioInput,
    RateBetweenInput,
    SliderInput
  },
  props: {
    highLowDesc: {
      type: Array,
      required: false,
      default: null
    },
    questionText: {
      type: String,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    gray: {
      type: Boolean,
      required: true
    },
    value: {
      validator: prop => typeof prop === 'number' || prop === null,
      required: true
    },
    scales: {
      type: Array,
      required: true
    },
    highlight: {
      type: Boolean
    },
    rateBetween: {
      type: Boolean
    },
    questionDropdown: {
      type: String,
      required: false,
      default: null
    },
    recspec: {
      type: Boolean,
      required: false,
      default: false
    },
    questionDesc: {
      type: String,
      required: false,
      default: null
    }
  },
  data () {
    return {
      lsh: 4,
      ds: 2,
      requirements: null

    }
  }
}
</script>

<style scoped>

.color1 {
  background-color: rgb(243, 247, 251);
}
.color2 {
  background-color: rgb(248, 251, 253)
}

.color1_op {
  background-color: rgb(243, 247, 251, 0.6);
}
.color2_op {
  background-color: rgb(248, 251, 253, 0.6)
}

.height_qn {
   max-width: 950px;
}

.height_rb {
   width: 100%;
}
.badge{
  height: 1.9rem !important;
  line-height: 2rem !important;
}
@media (min-width: 1024px) {
    .height_qn {
        width: 950px;
    }
     .height_qrb {
        width: 24rem;
    }
}

</style>
